import { VStack } from '@chakra-ui/react';
import type { NextPage, NextPageContext } from 'next';
import Head from 'next/head';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { Logo } from '../toolkit/components/Logo';
import { BodyBig, Heading1 } from '../toolkit/components/Texts';
import { useTranslation } from 'next-i18next';

const Home: NextPage = () => {
  const { t } = useTranslation(['common']);
  return (
    <>
      <Head>
        <title>Cardy</title>
        <meta name='description' content='Generated by Alfred' />
        <link rel='icon' href='/favicon.ico' />
      </Head>

      <main>
        <VStack gap={2}>
          <VStack
            border='1px solid'
            borderColor='grey.50'
            borderRadius='xl'
            p={8}
            alignItems='center'
            gap={16}
            mt={24}
          >
            <VStack spacing={0}>
              <Logo size={2} />
              <BodyBig>{t('cardyHeading')}</BodyBig>
            </VStack>

            <VStack gap={2} spacing={0}>
              <a href='mailto:info@cardy.ch'>info@cardy.ch</a>
              <a href='tel:+41797308022'>+41 79 730 80 22</a>
            </VStack>
          </VStack>
          <Heading1>Coming soon 🤩</Heading1>
        </VStack>
      </main>
    </>
  );
};

export default Home;

export async function getServerSideProps({ locale }: NextPageContext) {
  return {
    props: {
      ...(await serverSideTranslations(locale || 'de', ['common'])),
    },
  };
}
